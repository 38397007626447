import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['site']

  connect () {
    if (window.location.hash) {
      window.location.href = window.location
    }

    this.intersectionObserver = new window.IntersectionObserver(
      this.setActiveSite,
      {
        root: this.element,
        threshold: 0.99
      }
    )

    this.siteTargets.forEach((site) => {
      this.intersectionObserver.observe(site)
    })
  }

  /**
   *
   * @param {IntersectionObserverEntry[]} entries
   */
  setActiveSite (entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.scrollIntoView(true)
        window.location.hash = entry.target.id
        window.location.href = window.location
      }
    })
  }

  disconnect () {
    // stop watching the target area free up any memory
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect()
    }
  }
}
